// let screenWidth = window.innerWidth;
const BREAKPOINT = 992;
const BREAKPOINT_MOBILE = 599;
const triggerSideMenus = document.querySelectorAll('.trigger__sideMenu');
const sideMenu = document.querySelector('.header__sideMenu');


const handleMiniCart = document.querySelector('.cart__btn');
const miniCartBody = document.querySelector('.mini-cart__body');
const closeMiniCartTriggers = document.querySelectorAll('.js-mini-cart__close');

const bodyOverlay = document.querySelector('.body__overlay');

const body = document.getElementsByTagName('body')[0];

$(body).on( 'click', '.js-mini-cart__close2', function() {
    $('.trigger__sideMenu').removeClass('active');
    $('.mini-cart__body').removeClass('open');
    $('.body__overlay').removeClass('active');
    $('body').removeClass('cart-is-open');
    $('body').removeClass('menu-is-open');
});

document.addEventListener('DOMContentLoaded', function() {
    //menu
    if (triggerSideMenus) {
        triggerSideMenus.forEach(function (triggerSideMenu) {
            triggerSideMenu.addEventListener('click', function(event) {
                event.preventDefault();
                this.classList.toggle('active');
                sideMenu.classList.toggle('active');
                body.classList.toggle('menu-is-open');
                bodyOverlay.classList.toggle('active');
                if (sideMenu.classList.contains('active')) {
                    bodyOverlay.classList.add('active');
                }
                body.classList.remove('cart-is-open');

                miniCartBody.classList.remove('open');

		            $(".js-subcategories-target").each(function() {
			            closeMenuCategories.call(this, $.Event("click"));
		            });
            });
        });
    }

    if (handleMiniCart) {
        handleMiniCart.addEventListener('click', function handleClick(event) {
            miniCartBody.classList.add('open');
            body.classList.add('cart-is-open');
            body.classList.remove('menu-is-open');
            sideMenu.classList.remove('active');
            bodyOverlay.classList.add('active');
            triggerSideMenus.forEach(function (triggerSideMenu) {
                triggerSideMenu.classList.remove('active');
            });
        });
    }

		closeMiniCartTriggers.forEach(trigger => {
			trigger.addEventListener('click', function handleClick(event) {
				event.preventDefault();
				miniCartBody.classList.remove('open');
				body.classList.remove('menu-is-open');
				body.classList.remove('cart-is-open');
				bodyOverlay.classList.remove('active');
				triggerSideMenus.forEach(function (triggerSideMenu) {
					triggerSideMenu.classList.remove('active');
				});
			});
		});

    if (bodyOverlay) {
        bodyOverlay.addEventListener('click', function handleClick(event) {
            miniCartBody.classList.remove('open');
            body.classList.remove('cart-is-open');
            body.classList.remove('menu-is-open');
            sideMenu.classList.remove('active');
            bodyOverlay.classList.remove('active');
            triggerSideMenus.forEach(function (triggerSideMenu) {
                triggerSideMenu.classList.remove('active');
            });

		        $(".js-subcategories-target").each(function() {
			        closeMenuCategories.call(this, $.Event("click"));
		        });
        });
    }
});

window.addEventListener('scroll', () => {
    const header = document.querySelector('header');
    const scroll = window.scrollY;

    if ( scroll > 150) {
        header.classList.add('header--sticky');
    } else {
        header.classList.remove('header--sticky');
    }

});

function openMenuCategories(e) {
	e.preventDefault();
	const $item = $(this).closest(".item");
	const $target = $item.find(".js-subcategories-target").first();

	$(".js-subcategories-target").not($target).css("transform", "translateX(-100%)");

	if ($(window).width() > BREAKPOINT_MOBILE) {
		$target.css("transform", "translateX(100%)");
	}
	if ($(window).width() < BREAKPOINT_MOBILE) {
		$target.css("transform", "translateX(0%)");
	}
}


function closeMenuCategories(event) {
	event.stopPropagation();
	const $target = $(this).closest(".js-subcategories-target");
	$target.css("transform", "translateX(-100%)");
}

$(".js-subcategories-open").on("click", openMenuCategories);
$(".js-subcategories-close").on("click", closeMenuCategories);
const handleResize = () => {
	if ($(window).width() < BREAKPOINT) {
		$(".js-subcategories-target").each(function() {
			closeMenuCategories.call(this, $.Event("click"));
		});
		sideMenu.classList.remove('active');
		body.classList.remove('menu-is-open');
		bodyOverlay.classList.remove('active');
		triggerSideMenus.forEach(function (triggerSideMenu) {
			triggerSideMenu.classList.remove('active');
		});
	}
};
$(window).resize(handleResize);
