function debounce(func, timeout = 300) {
    let timer;
    return function (...args) {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export default class Search {
    #isOpen = false
    #$modal
    #$control
    #$content
    #$input
    #xhr

    constructor() {
        this.#$modal = $('#search-modal')
        this.#$control = this.#$modal.find('.searchModal__control')
        this.#$content = this.#$modal.find('.searchModal__inner')
        this.#$input = this.#$modal.find('.searchModal__input')
        const _this = this;
        this.#$input.on('input', debounce(function () {
            const keyword = $(this).val()
            // min length
            if (keyword.length < 4) return

            // abort previous requests
            if (_this.#xhr?.readyState === 1) _this.#xhr.abort()

            const route = 'extension/module/d_ajax_search/searchresults'
            _this.#xhr = $.getJSON('index.php', {
                keyword,
                route
            }, (data, status) => {
                if ('success' === status) {
                    _this.result(data)
                }
            })
        }))
        $(document).on('keyup', (e) => {
            if(e?.keyCode === 27 && this.#isOpen) {
                this.close()
            }
        });
    }

    open() {
        if (this.#isOpen) return
        this.#$modal.show()
        this.#$control.css({opacity: 0})
        this.#$content.css({top: 200})
        this.#$control.animate({
            opacity: 1
        }, 200)
        this.#$content.animate({
            top: 0
        }, 400)
        this.#isOpen = true
    }

    close() {
        if (!this.#isOpen) return
        this.#$content.animate({
            top: -200
        }, 400)
        this.#$control.animate({
            opacity: 1
        }, 200, () => {
            this.#$modal.hide()
        })
        this.#isOpen = false
    }

    result(data) {
        this.#$modal.find('.searchModal__results').empty()
        this.#$modal.find('.searchModal__no-results').hide()
        // Filter products only
        data = data.filter((i) => i.where_find === 'product')

        if (!data.length) {
            this.#$modal.find('.searchModal__no-results').show()
            return
        }

        const html = data.reduce((acc, row) => {
            acc += '' +
                '<div class="search-result">' +
                '   <a class="search-result__category" href="' + row.category_href + '">' + row.category_name + '</a>' +
                '   <a class="search-result__product" href="' + row.href + '">' +
                '       <div class="search-result__img">' +
                '           <img src="' + row.image + '" alt="' + row.name + '"/>' +
                '       </div>' +
                '       <div class="search-result__info">' +
                '           <div class="search-result__name">' + row.name + '</div>' +
                '           <div class="search-result__price">' + row.price + '</div>' +
                '       </div>' +
                '   </a>' +
                '</div>'
            return acc
        }, '')

        this.#$modal.find('.searchModal__results').append($(html))
    }
}