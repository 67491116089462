import { debounce } from "lodash";
import Search from "./Search";

const app = {
    customCSSProperties: new Proxy({}, {
        set(obj, prop, value) {
            document.documentElement.style.setProperty(`--${prop}`, value);
            return Reflect.set(...arguments);
        }
    }),
    /**
     * Set customCSSProperties property
     * @param {string} name
     * @param {string} value
     */
    setCustomCSSProperty(name, value) {
        this.customCSSProperties[name] = value;
    },
    /**
     * Get customCSSProperties property
     * @param {string} name customCSSProperties key
     */
    getCustomCSSProperty(name) {
        return this.customCSSProperties[name];
    },
    Search: new Search
};

const setGlobalCSSProperties = () => {
    app.setCustomCSSProperty('header-height', document.getElementById('main-header').offsetHeight + 'px')
    app.setCustomCSSProperty('screen-height', window.innerHeight + 'px')
}

const setGlobalCSSPropertiesDebounced = debounce(setGlobalCSSProperties, 100)

document.addEventListener("DOMContentLoaded", () => {
    setGlobalCSSProperties()
    window.addEventListener('resize', setGlobalCSSPropertiesDebounced)
})


window.app = app;